<template>
  <modal-wrapper
    :id="id"
    hide-footer
    size="md"
    :on-modal-hidden="uploadReset"
  >
    <template #title>
      Carica nuova fattura
    </template>
    <template #body>
      <div>
        <div v-if="!hasErrors">
          <b-form @submit.prevent="submitForm" class="form-inputs--mobile--lg">
            <vue-dropzone
              ref="imgDropZone"
              :id="dropZoneId"
              :options="dropzoneOptions"
              @vdropzone-success="afterSuccess"
              @vdropzone-complete="afterComplete"
              @vdropzone-error="afterError"
            ></vue-dropzone>
          </b-form>
        </div>
        <div v-if="hasErrors" class="text-center py-3">
          <div v-if="error !== 'wrongOwner'">
            <p>La fattura risulta già presente</p>
          </div>
          <div v-if="error === 'wrongOwner'">
            <p>
              La fattura non risulta associata a <strong>{{currentName}}</strong>
            </p>
          </div>
          <div class="mt-3">
            <b-button
              variant="warning"
              @click="uploadReset"
              class="btn-sm"
            >
              Carica un'altra fattura
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import TokenService from '@/services/token.service';
import {
  isNotEmpty,
} from '@/utils/validators';
import {
  extensionInvoice,
  // mimeTypeInvoice,
  getEntityApiBase,
} from '@/utils/documents';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalAddInvoice',
  props: {
    id: String,
    imageType: String,
    entityType: String,
    entityId: String,
    documentEntityType: String,
    onSuccess: Function,
  },
  components: {
    ModalWrapper,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      submitted: false,
      showCompany: false,
      hasErrors: false,
      error: null,
    };
  },
  computed: {
    dropZoneId() {
      return `${this.id}_dropzone`;
    },
    dropzoneOptions() {
      const apiBase = getEntityApiBase('INVOICE');
      const url = `${apiBase}/api/upload-invoice`;
      return {
        url,
        thumbnailWidth: 350,
        thumbnailHeight: 100,
        addRemoveLinks: false,
        acceptedFiles: extensionInvoice.join(),
        headers: TokenService.authHeader(),
        dictDefaultMessage: `<p class='text-default'>
                                <font-awesome-icon icon="cloud-upload-alt"></font-awesome-icon>
                                 Trascina una fattura o fai click in quest'area
                             </p>
          <p class="form-text">File accettati: ${extensionInvoice.join()}</p>
          `,
      };
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    currentName() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/currentCompany'].info.companyName;
      }
      return this.$store.getters['auth/currentName'];
    },
  },
  methods: {
    async afterSuccess(file, response) {
      console.log('response', response);
      if (isNotEmpty(this.onSuccess)) {
        this.onSuccess(response.data);
      }
      this.$bvModal.hide(this.id);
    },
    async afterComplete() {
      // this.$bvModal.hide(this.id);
      console.log('afterComplete');
    },
    async afterError(file, message, xhr) {
      console.log('response error', file, message, xhr);
      if (xhr?.status === 409) {
        console.log('409');
        // 409 - private boolean wrongOwner FALSE > già presente
        if (!message.wrongOwner) {
          console.log('duplicata');
          this.error = 'alreadyPresent';
        }
        // 409 - private boolean wrongOwner TRUE > fattura di un'altra azienda
        if (message.wrongOwner) {
          console.log('azienda diversa');
          this.error = 'wrongOwner';
        }
        this.hasErrors = true;
      }
    },
    submitForm() {
      console.log('submit');
    },
    uploadReset() {
      // this.$refs.imgDropZone.disable(); // not needed > the v-if destroys the component
      this.hasErrors = false;
      this.error = null;
    },
  },
};
</script>

<style scoped>

</style>
